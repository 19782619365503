// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-visibility-interval-selector {
    cursor: pointer;
    font-size: 16px;
}

.transaction-visibility-heading {
    font-weight: bold;
    color: green;
    font-family: sans-serif;
    font-size: 16px;
}

.transaction-visibility-graphs{
    display: flex;
    align-items: center;
}

.transaction-visibility-products{
    color: orange;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/transaction-action-center/TransactionActionCenter.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".transaction-visibility-interval-selector {\n    cursor: pointer;\n    font-size: 16px;\n}\n\n.transaction-visibility-heading {\n    font-weight: bold;\n    color: green;\n    font-family: sans-serif;\n    font-size: 16px;\n}\n\n.transaction-visibility-graphs{\n    display: flex;\n    align-items: center;\n}\n\n.transaction-visibility-products{\n    color: orange;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    font-size: 1.2rem;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
