import React, { useState, useEffect, useMemo } from "react";
import "./Tableau.css";

type TableauIframeProps = {
    iframe: {
        id: string,
        url: string
    }
}

const TableauIframe: React.FC<TableauIframeProps> = ({ iframe }) => {
    
    return (
        <iframe
            className="iframe-container no-border mid-height"
            id={iframe.id}
            scrolling="no"
            src={iframe.url}
        ></iframe>
    )
}
export default TableauIframe;
