// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-visibility-header {
    font-size: 23px;
    color: green;
    margin-right: 20px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}
.transaction-visibility-error{
    color: red;
}
.transaction-visibility-container,
.transaction-visibility-top,
.transaction-visibility-bottom {
    display: flex;
    justify-content: flex-start;
    padding: 8px;
}

.transaction-visibility-container {
    flex-direction: column;
    width: 98%;
    height: 100%;
}

.transaction-visibility-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 230px;
}

.transaction-visibility-time-range {
    width: 50%;
}

.transaction-visibility-meta-data-container {
    width: 50%;
}

.transaction-visibility-statistics {
    width: 50%;
}

.transaction-visibility-store-card {
    width: 50%;
}

.transaction-visibility-bottom {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.transaction-visibility-table-container {
    width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/components/kpi-dashboard-tabs/transaction-visibility/main/TransactionVisibility.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,+BAA+B;AACnC;AACA;IACI,UAAU;AACd;AACA;;;IAGI,aAAa;IACb,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,UAAU;AACd","sourcesContent":[".transaction-visibility-header {\n    font-size: 23px;\n    color: green;\n    margin-right: 20px;\n    font-weight: 500;\n    font-family: Roboto, sans-serif;\n}\n.transaction-visibility-error{\n    color: red;\n}\n.transaction-visibility-container,\n.transaction-visibility-top,\n.transaction-visibility-bottom {\n    display: flex;\n    justify-content: flex-start;\n    padding: 8px;\n}\n\n.transaction-visibility-container {\n    flex-direction: column;\n    width: 98%;\n    height: 100%;\n}\n\n.transaction-visibility-top {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    min-height: 230px;\n}\n\n.transaction-visibility-time-range {\n    width: 50%;\n}\n\n.transaction-visibility-meta-data-container {\n    width: 50%;\n}\n\n.transaction-visibility-statistics {\n    width: 50%;\n}\n\n.transaction-visibility-store-card {\n    width: 50%;\n}\n\n.transaction-visibility-bottom {\n    display: flex;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.transaction-visibility-table-container {\n    width: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
