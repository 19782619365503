// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.no-border{
    border: none;
}

.mid-height{
    height: 510px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/tableau/Tableau.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".iframe-container{\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.no-border{\n    border: none;\n}\n\n.mid-height{\n    height: 510px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
