// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpi-dashboard-tabs-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}

.kpi-dashboard-tabs {
    width: 60px;
    min-width: 60px;
    overflow-y: auto;
    position: sticky;
    left: 0;
}

.kpi-dashboard-each-tab {
    min-width: 0 !important;
}

.kpi-dashboard-tabs .MuiTab-root:hover {
    transform: scale(1.25);
    transition: transform 0.2s ease;
}

.tabpanel-box {
    padding: 4px 4px 1px 2px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.tabpanel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
}

.kpi-dashboard-tabs .MuiTabs-indicator {
    background-color: rgba(0, 128, 0, 1);
}

.kpi-dashboard-tabs .MuiTab-root {
    color: rgba(0, 0, 0, 0.6);
}

.kpi-dashboard-tabs .MuiTab-root svg {
    color: rgba(0, 0, 0, 0.6);
    font-size: 28px;
}

.kpi-dashboard-tabs .Mui-selected {
    color: rgba(0, 128, 0, 1);
}

.kpi-dashboard-tabs .Mui-selected svg {
    color: rgba(0, 128, 0, 1);
}`, "",{"version":3,"sources":["webpack://./src/components/common/material-tab/KpiTabs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,OAAO;AACX;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;IACtB,+BAA+B;AACnC;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".kpi-dashboard-tabs-container {\n    display: flex;\n    flex-grow: 1;\n    justify-content: center;\n}\n\n.kpi-dashboard-tabs {\n    width: 60px;\n    min-width: 60px;\n    overflow-y: auto;\n    position: sticky;\n    left: 0;\n}\n\n.kpi-dashboard-each-tab {\n    min-width: 0 !important;\n}\n\n.kpi-dashboard-tabs .MuiTab-root:hover {\n    transform: scale(1.25);\n    transition: transform 0.2s ease;\n}\n\n.tabpanel-box {\n    padding: 4px 4px 1px 2px;\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n}\n\n.tabpanel {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    min-width: 0;\n}\n\n.kpi-dashboard-tabs .MuiTabs-indicator {\n    background-color: rgba(0, 128, 0, 1);\n}\n\n.kpi-dashboard-tabs .MuiTab-root {\n    color: rgba(0, 0, 0, 0.6);\n}\n\n.kpi-dashboard-tabs .MuiTab-root svg {\n    color: rgba(0, 0, 0, 0.6);\n    font-size: 28px;\n}\n\n.kpi-dashboard-tabs .Mui-selected {\n    color: rgba(0, 128, 0, 1);\n}\n\n.kpi-dashboard-tabs .Mui-selected svg {\n    color: rgba(0, 128, 0, 1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
