import OpisHistoryTableau from "../components/kpi-dashboard-tabs/opis-history/OpisHistoryTableau";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import ViewListIcon from '@mui/icons-material/ViewList';
import BalanceIcon from '@mui/icons-material/Balance';
import { useEffect, useState } from "react";
import KpiTabs, { kpiTabsProps } from "../components/common/material-tab/KpiTabs";
import { RowSelectionType } from "../types/RowSelectionType";
import DisabledScreen from "../components/common/disabled-screen/DisabledScreen";
import VolumePerformanceTableau from "../components/kpi-dashboard-tabs/volume-performance/VolumePerformanceTableau";
import PerformanceKPITableau from "../components/kpi-dashboard-tabs/performance-kpi/PerformanceKPITableau";
import ProximityWrapper from "../components/kpi-dashboard-tabs/price-comparison/PriceComparisionWrapper";
import { KpiDashBoardMountProps } from "../types/KpiDashboardMountProps";
import { StoreProximityMethods, StoreProximityProps } from "../types/StoreProximityProps";
import { MarkerInfo } from "../types/MarkerInfo";
import { KpiDashboardInfoData } from "../types/KpiDashboardProps";
import { TransactionVisibilityMethods, TransactionVisibilityProps } from "../types/TransactionVisibilityProps";
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import TransactionVisbilityWrapper from "../components/kpi-dashboard-tabs/transaction-visibility/main/TransactionVisbilityWrapper";


const KpiDashboard: React.FC<KpiDashBoardMountProps> = ({customProps}) => {
  const [storeId, setStoreId] = useState<string>();
  const [requestedTab, setRequestedTab] = useState<number>(0);
  const [proximityProps, setProximityProps] = useState<StoreProximityProps>(undefined);
  const [isStoreProximityEnabled, setIsStoreProximityEnabled] = useState<any>(false);
  const [rowSelectionType, setRowSelectionType] = useState<RowSelectionType>('Single');
  const [transactionVisibilityProps, setTransactionVisibilityProps] = useState<TransactionVisibilityProps>(undefined);

  useEffect(() => {
    customProps.kpiReportsBehaviour?.subscribe((kpiDashboardProps: KpiDashboardInfoData) => {
      if(!kpiDashboardProps.isOpen) return;
      setBaseMetaDataInfo(kpiDashboardProps);
      setTransactionVisibilityMetaDataInfo(customProps.transactionVisibilityFunctions, kpiDashboardProps.reports.markerInfo);
      setProximityMetaDataIfAvailable(customProps.storeProximityFunctions, kpiDashboardProps.reports.markerInfo);
    });
  }, [customProps.kpiReportsBehaviour.getValue().reports?.markerInfo?.importcode]);

  function setBaseMetaDataInfo(kpiDashboardProps: KpiDashboardInfoData){
    setStoreId(kpiDashboardProps.reports?.markerInfo?.importcode);
    setRowSelectionType(kpiDashboardProps.rowSelectionType);
    setRequestedTab(kpiDashboardProps.tab);
  }

  function setTransactionVisibilityMetaDataInfo(transactionVisibilityFunctions: TransactionVisibilityMethods, markerInfo: MarkerInfo){
    const transactionVisbilityProps: TransactionVisibilityProps = {
      storeId: markerInfo.importcode,
      transactionVisibilityFunctions: transactionVisibilityFunctions
    }
    setTransactionVisibilityProps(transactionVisbilityProps);
  }

  function setProximityMetaDataIfAvailable(storeProximityFunctions: StoreProximityMethods, markerInfo: MarkerInfo){
    if (markerInfo?.isProximityEnabled || true) {
      const storeProximityProps: StoreProximityProps = {
        storeProximityFunctions: storeProximityFunctions,
        storeData: markerInfo
      };
      setProximityProps(storeProximityProps);
      setIsStoreProximityEnabled(true);
    }else{
      setProximityProps(undefined);
      setIsStoreProximityEnabled(false);
    }
  }

  const defaultListOfTabs = [
    { isEnabled: true, label: 'Transaction Visibility', icon: <LocalGasStationIcon />, component: <TransactionVisbilityWrapper {...transactionVisibilityProps} />},
    { isEnabled: true, label: 'Volume Performance', icon: <VisibilityIcon />, component: <VolumePerformanceTableau storeId={storeId} /> },
    { isEnabled: true, label: 'Performance KPI', icon: <ViewListIcon />, component: <PerformanceKPITableau storeId={storeId} /> },
    { isEnabled: true, label: 'OPIS History', icon: <TrendingUpOutlinedIcon />, component: <OpisHistoryTableau storeId={storeId} /> },
    { isEnabled: isStoreProximityEnabled , label: 'Store Proximity Analysis', icon: <BalanceIcon />, component: <ProximityWrapper {...proximityProps} /> },
  ];


  const showTabs = rowSelectionType === 'Single' ? true : false;
  const kpiTabsProps: kpiTabsProps = {listOfTabs: defaultListOfTabs, requestedTab: requestedTab}
  
  return (
    <>
      {showTabs && <KpiTabs {...kpiTabsProps} />}
      {!showTabs && <DisabledScreen rowSelectionType={rowSelectionType} />}
    </>

  );
}

export default KpiDashboard;

